
import axiosInstance from "..";
import { IScenario } from "../../components/pages/SceneManagement/ListOfScenario";
interface IScenarioId{
  id:string;
}
export async function getScenario(inputQuery?:any) {
  let headers = {
    Authorization: `${localStorage.getItem("tokenUser")}`,
  };
  return axiosInstance
    .get(`${process.env.REACT_APP_API}/v1/scenarios`, {
      headers: headers,
      params: inputQuery
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export async function createScenarios(data: Array<IScenario>) {
  let headers = {
    Authorization: `${localStorage.getItem("tokenUser")}`,
  };
  return axiosInstance
    .post(`${process.env.REACT_APP_API}/v1/scenarios/insert-many`, data, {
      headers: headers,
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export async function updateScenarios(data: IScenario) {
  let headers = {
    Authorization: `${localStorage.getItem("tokenUser")}`,
  };
  return axiosInstance
    .patch(`${process.env.REACT_APP_API}/v1/scenarios/update-data`, data, {
      headers: headers,
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export async function deactivateScenarios(id: string) {
  let headers = {
    Authorization: `${localStorage.getItem("tokenUser")}`,
  };
  return axiosInstance
    .patch(`${process.env.REACT_APP_API}/v1/scenarios/deactivate`, { scenarioid: id }, {
      headers: headers,
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export async function addFavScenario(id: string) {
  let headers = {
    Authorization: `${localStorage.getItem("tokenUser")}`,
  };
  return axiosInstance
    .patch(`${process.env.REACT_APP_API}/v1/scenarios/add-favourite`, { scenarioid: id }, {
      headers: headers,
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export async function deleteFavScenarios(id: string) {
  let headers = {
    Authorization: `${localStorage.getItem("tokenUser")}`,
  };
  return axiosInstance
    .patch(`${process.env.REACT_APP_API}/v1/scenarios/remove-favourite`, { scenarioid: id }, {
      headers: headers,
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export async function deleteScenarioMany(id: any) {
  let headers = {
    Authorization: `${localStorage.getItem("tokenUser")}`,
  };
  return axiosInstance
    .patch(`${process.env.REACT_APP_API}/v1/scenarios/deactivate-many`,id, {
      headers: headers,
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}







