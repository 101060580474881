import React, { ReactNode, useEffect, useState } from "react";
import * as Yup from "yup";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  Typography,
} from "@material-ui/core";
import { ButtonContained, ButtonText } from "../../../commons/Buttons";
import {
  addClassroomsApi,
  deactiveClassroomsApi,
  fetchClassroomsApiPagination,
  fetchClassroomsMeApiPagination,
  fetctDisplayResultTriage,
  updateClassroomsApi,
} from "../../../../services/Classrooms/classroom.service";
import _ from "lodash";
import Layout from "../../../commons/Layout";
import Preview from "./Dialog/Preview";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import Table from "./Table";
import ManagementClassroom from "./Dialog/ManagementClassroom";
import AddCircleRoundedIcon from "@material-ui/icons/AddCircleRounded";
import generator from "generate-password";
import RecheckDialog from "../../../commons/RecheckDialog";
import AlertDialog from "../../../commons/AlertDialog";
import VideoReplay from "./Dialog/VideoReplay";
import PatientsDetail from "./Dialog/PatientsDetails";
import ExportClassResultTest from "./Dialog/ExportClassResult";
import Certificate from "./Dialog/Certificate";
import UserDetails from "./Dialog/UserDetails";
import { useStyles } from "./styles";
import { useSnackbar } from "notistack";
import { useFormik } from "formik";
import { checkPagePermission } from "../../../commons/CheckRole";
import { useAuth } from "../../../../contexts/AuthContext";
import { ITextConfirmDetail } from "../../ProfileManagement/Profile";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import RemoveIcon from "@material-ui/icons/Remove";
import { SvgIconComponent } from "material-table";
import {
  initialPagination,
  IPaginationData,
} from "../../../commons/Pagination";
import { Pagination } from "@material-ui/lab";
import { ReactComponent as DownloadIcon } from "../../../commons/asset/downloadWhite.svg";
import { ISrot } from "../../UserManagement/User";
import { useHistory, useLocation } from "react-router-dom";
export interface IClassRoom {
  classId: string;
  createdAt: string;
  classDate: string;
  status: string;
  instructorId: IInstructor;
  action: any;
  sessions: any;
}
interface IInstructor {
  fullName: string;
}
interface ITextConfirm {
  DELETE?: ITextConfirmDetail;
  CREATE?: ITextConfirmDetail;
  UPDATE?: ITextConfirmDetail;
  QUITEDITING?: ITextConfirmDetail;
}

const TextConfirmPopup: ITextConfirm = {
  DELETE: {
    title: "Are you sure to delete this class?",
    subTitle: "This action cannot be rolled back.",
    yes: "Delete",
    no: "Cancel",
    color: "red",
  },
  CREATE: {
    title: "Are you sure to create this class?",
    subTitle: "This action cannot be rolled back.",
    yes: "Create",
    no: "Cancel",
    color: "blue",
  },
  UPDATE: {
    title: "Are you sure to edit this class?",
    subTitle: "This action cannot be rolled back.",
    yes: "Yes",
    no: "No",
    color: "blue",
  },
  QUITEDITING: {
    title: "Quit editing?",
    subTitle: "Changes you made so far will not be saved.",
    yes: "Yes",
    no: "No",
    color: "red",
  },
};
const action = [
  { display: "Go to VR  Spectator", color: "blue", code: "GOTOVRSPECTATOR" },
  { display: "More Information", color: "green", code: "PREVIEW" },
  { display: "Delete Class", color: "red", code: "DELETE" },
];
export function handleIconResult(text: string):string {
  if(text === "SUCCESS"){
    return "✓";
  }
  else{
    return "✘"
  }
}
const ClassroomManagement = () => {
  dayjs.extend(utc);
  dayjs.extend(timezone);
  dayjs.tz.guess();
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const location: any = useLocation();
  const { currentUser } = useAuth();
  const [forceUpdateData, setForceUpdateData] = useState(0); 
  const [acessLocation, setAccessLocation] = useState<Boolean>(false);
  const [classrooms, setClassrooms] = useState<IClassRoom[]>([]);
  const [selectData, setSelectData] = useState<any>({});
  const [selectDataUser, setSelectDataUser] = useState<any>({});
  const [open, setOpen] = useState(false);
  const [openRecheck, setOpenRecheck] = useState(false);
  const [reCheckOnExit, setRecheckOnExit] = useState(false) 
  const [state, setState] = useState("");
  const [confirmPopup, setConfirmPopup] = useState<ITextConfirmDetail>();
  const [loading, setLoading] = useState(true);
  const [dataLogTriagePatients, setDataLogTriagePatients] = useState<any>();
  const [pagination, setPagination] = useState<IPaginationData>(
    initialPagination
  );
  const [sortValue, setSortValue] = useState<ISrot>({
    sortByStatus: "",
  });
  const validationSchema = Yup.object().shape({
    scenario: Yup.object({
      code: Yup.string().required("Scenario is required"),
    }),
    classId: Yup.string().required("ClassId is required"),
    date: Yup.date().required("Date is required"),
    time: Yup.string().required("Time is required"),
    instructors: Yup.array().min(1).required("fieldData.fieldRequire"),
    students: Yup.array().min(1).required("fieldData.fieldRequire"),
  });
  const formik = useFormik({
    initialValues: !_.isEmpty(selectData)
      ? {
          ...selectData,
          date: dayjs(selectData.classDate).format("YYYY-MM-DD"),
          time: dayjs(selectData.classDate).format("HH:mm"),
        }
      : {
          scenario: "",
          certificate: false,
          date: "",
          time: "",
          classId: "",
          instructors: [],
          students: [],
          email: "",
        },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      changeState(state, undefined);
      handleOpenRecheck();
    },
  });
  const handleDisableExportClassResult = () => {
    const logPatientByUser = dataLogTriagePatients.logTriage.filter(
      (ele: any) => {
        return ele.studentId === selectDataUser._id;
      }
    );
    if(logPatientByUser.length > 0){
      return false;
    }
    else{
      return true;
    }
  }
  function handleDialogAction(): ReactNode {
    return (
      <div
        style={{
          justifyContent:
            state === "CREATE" || state === "UPDATE"
              ? "flex-end"
              : "flex-start",
        }}
      >
        {state === "CREATE" || state === "UPDATE" ? (
          <DialogActions style={{ padding: "10px 24px 10px 0px" }}>
            <ButtonContained
              onClick={handleRecheckOnExit}
              style={{ marginRight: 20 }}
              colorText="white"
            >
              Cancel
            </ButtonContained>
            <ButtonContained
              onClick={() => formik.handleSubmit()}
              colorText="blue"
            >
              {state === "CREATE" ? "Create" : "Save"}
            </ButtonContained>
          </DialogActions>
        ) : state !== "PREVIEW" ? (
          <DialogActions style={{ padding: "10px 24px 10px 0px",justifyContent:'space-between' }}>
            <ButtonText
              onClick={() => changeState("PREVIEW", null)}
              style={{ marginRight: 20 }}
              colorText="blue"
            >
              Back
            </ButtonText>
            {state === "USERDETAILS" &&  
              <Button style={{backgroundColor:handleDisableExportClassResult() ?'rgba(156, 155, 157, 0.42)' :'#60D0A1', borderRadius:"40px", textTransform:'none', color:'white', padding:'8px 20px'}}
                href={linkUrlClassroomByUser()}
                disabled={handleDisableExportClassResult()}
                target="_blink"
              >               
                <DownloadIcon style={{marginRight:10}}/> Export Class Result
              </Button>
            }

          </DialogActions>
        ) : (
          ""
        )}
      </div>
    );
  }
  function fetchClassrooms() {
    checkPagePermission(currentUser?.roles, "ADMIN")
      ? fetchClassroomsApiPagination({
          page: pagination.page - 1,
          limit: 10,
          status: sortValue.sortByStatus,
          mode: "SIMULATION"
        }).then((res) => {
          if (res && res.data && res.data.result) {
            let mappedData = res.data.result.map((item: any) => {
              return { ...item, action };
            });
            setPagination({ ...pagination, totalPages: res.data.pageAmount });
            setClassrooms(mappedData);
            setLoading(false);
          }
        })
      : fetchClassroomsMeApiPagination({
          page: pagination.page - 1,
          limit: 10,
          mode: "SIMULATION"
        }).then((res) => {
          if (res && res.data && res.data.result) {
            let mappedData = res.data.result.map((item: any) => {
              return { ...item, action };
            });
            setPagination({ ...pagination, totalPages: res.data.pageAmount });
            setClassrooms(mappedData);
            setLoading(false);
          }
        });
  }
  function changeState(state: string, data: any) {
    if (data && data.hasOwnProperty("classId")) {
      setSelectData(data);
    } else {
      setSelectDataUser(data);
    }
    setState(state);
    if (state === "DELETE") {
      setConfirmPopup(TextConfirmPopup[state]);
      handleOpenRecheck();
    } else if (state === "PREVIEW") {
      handleOpen();
    } else if (state === "CREATE") {
      if(!open){
        genClassId();
      }
      setConfirmPopup(TextConfirmPopup[state]);
      handleOpen();
    } else if (state === "UPDATE") {
      setConfirmPopup(TextConfirmPopup[state]);
    } else if (state === "GOTOVRSPECTATOR") {
      history.push({pathname: `/vr-spectator`, state: data});
    }
    setRecheckOnExit(false);
  }
  function handleSubmit() {
    if (reCheckOnExit) {
      if (reCheckOnExit && state === "CREATE") {
        handleClose();
        handleCloseRecheck();
      } else if (reCheckOnExit && state === "UPDATE") {
        changeState("PREVIEW", null);
        handleCloseRecheck();
        formik.resetForm();
        setRecheckOnExit(false);
      }
    } else {
      if (state === "CREATE") {
        onCreateClassroom();
      } else if (state === "UPDATE") {
        onUpdateClassroom();
      } else if (state === "DELETE") {
        onDeactive(selectData);
      }
      resetDialog();
    }
  }
  function resetDialog() {
    handleClose();
    handleCloseRecheck();
  }
  function handleRecheckOnExit() {
    if(state === "CREATE"){
      if( formik.values.date || formik.values.time || formik.values.scenario || formik.values.instructors.length > 0 || formik.values.students.length > 0 || formik.values.certificate) {
        setConfirmPopup(TextConfirmPopup["QUITEDITING"]);
        setRecheckOnExit(true);
        handleOpenRecheck();   
      }
      else {
        handleClose();
      }
    }
    else if(state === "UPDATE"){
      const instructors = selectData.instructors.map((ele:any)=>ele._id);
      const students = selectData.students.map((ele:any)=>ele._id);
  
      const instructorsNew = formik.values?.instructors.map((ele:any)=>ele._id);
      const studentsNew = formik.values?.students.map((ele:any)=>ele._id);
  
      const haveInstructors = instructorsNew?.every((ele:any) => instructors?.includes(ele));
      const haveStudents = studentsNew?.every((ele:any)=> students?.includes(ele));

      if(formik.values.date !== dayjs(selectData?.classDate).format('YYYY-MM-DD') || formik.values.time !== dayjs(selectData?.classDate).format('HH:mm') || formik.values.scenario?.code !== selectData?.scenario?.code || !haveInstructors || !haveStudents || selectData?.certificate !== formik.values?.certificate || formik.values.instructors < 1 || formik.values.students < 1 || instructors.length !== instructorsNew.length || students.length !== studentsNew.length) {
        setConfirmPopup(TextConfirmPopup["QUITEDITING"]);
        setRecheckOnExit(true);
        handleOpenRecheck();   
      }
      else {
        changeState("PREVIEW", null);
      }
    }
    else {
      handleClose();
    }
  }
  function handleClose() {
    setOpen(false);
    formik.resetForm();
    setSelectData({});
    setRecheckOnExit(false);
  }
  function handleOpen() {
    setOpen(true);
  }
  function handleCloseRecheck() {
    setOpenRecheck(false);
  }
  function handleOpenRecheck() {
    setOpenRecheck(true);
  }
  function onCreateClassroom() {
    let mappedInstructorId = formik.values.instructors.map(
      (item: any) => item._id
    );
    let mappedStudentId = formik.values.students.map((item: any) => item._id);
    const data = [
      {
        classId: formik.values.classId,
        certificate: formik.values.certificate,
        classDate: dayjs(formik.values.date + " " + formik.values.time),
        instructors: mappedInstructorId,
        scenario: formik.values.scenario._id,
        mode: "SIMULATION",
        students: mappedStudentId,
      },
    ];
    addClassroomsApi(data)
      .then((res) => {
        if (res.data) {
          setForceUpdateData((prev: any) => prev + 1);
          enqueueSnackbar("Created Success", {
            variant: "success",
          });
        } else {
          enqueueSnackbar("Created Error", {
            variant: "error",
          });
        }
      })
      .catch(() => {
        enqueueSnackbar("Created Error", {
          variant: "error",
        });
      });
  }
  function onUpdateClassroom() {
    let mappedInstructorId = formik.values.instructors.map(
      (item: any) => item._id
    );
    let mappedStudentId = formik.values.students.map((item: any) => item._id);
    const data = {
      classroomid: selectData._id,
      classId: formik.values.classId,
      certificate: formik.values.certificate,
      classDate: dayjs(formik.values.date + " " + formik.values.time),
      scenario: formik.values.scenario._id,
      instructors: mappedInstructorId,
      students: mappedStudentId,
    };
    updateClassroomsApi(data)
      .then((res) => {
        setForceUpdateData((prev: any) => prev + 1);
        enqueueSnackbar("Updated Success", {
          variant: "success",
        });
      })
      .catch(() => {
        enqueueSnackbar("Updated Error", {
          variant: "error",
        });
      });
  }
  function onDeactive(selectData: any) {
    const data = {
      classroomid: selectData._id,
    };
    deactiveClassroomsApi(data)
      .then(() => {
        setForceUpdateData((prev: any) => prev + 1);
        enqueueSnackbar("Deleted Classroom Success", {
          variant: "success",
        });
      })
      .catch(() => {
        enqueueSnackbar("Updated Classroom Error", {
          variant: "error",
        });
      });
  }
  function linkUrlClassroomByUser(): string {
    return `${process.env.REACT_APP_API}/v1/pdf/read-pdf-result-triage-user/${formik.values._id}/${selectDataUser._id}`;
  }
  function linkUrlClassroom(): string {
    return `${process.env.REACT_APP_API}/v1/pdf/read-pdf-result-triage-classroom/${formik.values._id}`;
  }
  function genClassId() {
    const classId = generator.generate({
      length: 10,
      lowercase: false,
      uppercase: false,
      numbers: true,
      symbols: false,
    });
    formik.setFieldValue("classId", classId);
  }
  function handleBtnDownload(): Array<string> | undefined {
    if (state === "CERTIFICATE") {
      return ["Download Certificate", linkUrlClassroom()];
    } else if (state === "VIDEOREPLAY") {
      return ["Download Video", linkUrlClassroomByUser()];
    } else if (state === "EXPORTCLASSRESULT") {
      return ["Download Class Result", linkUrlClassroom()];
    }
  }
  function handleSubTitle(): string {
    if (state === "PATIENTDETAILS") {
      return `Date ${dayjs(selectData?.classDate).format('DD/MM/YYYY  HH:mm A')}`;
    } else if ( state === "EXPORTCLASSRESULT" || state === "CERTIFICATE" || state === "VIDEOREPLAY" ) {
      return "Date  22/11/2021 01.00 PM ";
    } else {
      return "";
    }
  }
  function handlePagination(event: React.ChangeEvent<unknown>, value: number) {
    setPagination({ ...pagination, page: value });
  }
  useEffect(() => {
    if (Array.isArray(currentUser?.roles)) {
      fetchClassrooms();
    }
  }, [forceUpdateData, currentUser, pagination.page, sortValue.sortByStatus]);
  useEffect(() => {
    if (formik.values.status === "FINISHED") {
      fetctDisplayResultTriage(formik.values._id).then((res) => {
        if(res?.data?.result)
        setDataLogTriagePatients(res.data.result);
      });
    }
  }, [formik.values]);
  function handleDialogContent(stateContent: string) {
    const components: any = {
      CREATE: <ManagementClassroom formik={formik} />,
      UPDATE: <ManagementClassroom formik={formik} />,
      PREVIEW: <Preview formik={formik} changeState={changeState} dataLogTriagePatients={dataLogTriagePatients} />,
      VIDEOREPLAY: <VideoReplay />,
      PATIENTDETAILS: <PatientsDetail formik={formik} dataLogTriagePatients={dataLogTriagePatients} />,
      EXPORTCLASSRESULT: <ExportClassResultTest formik={formik} dataLogTriagePatients={dataLogTriagePatients} />,
      CERTIFICATE: <Certificate />,
      USERDETAILS: <UserDetails selectDataUser={selectDataUser} formik={formik} dataLogTriagePatients={dataLogTriagePatients} />,
    };
    const Component = components[stateContent];
    return Component;
  }
  useEffect(() => {
    if (location.state && !loading && !acessLocation) {
      // const classroom: Array<IClassRoom> = classrooms.filter(
      //   (el) => el.classId === location.state.classId
      // );
      setSelectData(location.state.classroom);
      setState("PREVIEW");
      setAccessLocation(true);
      if (selectData) {
        handleOpen();
      }
    }
  }, [loading, formik.values]);
  
  return (
    <Layout
      pageAt={"classManagement"}
      render={(props: any) => (
        <div className={`${classes.root}`}>
          <div>
            <Box display="flex" alignItems="center">
              <Typography
                style={{
                  color: "#5A5A5B",
                  fontWeight: 700,
                  fontSize: 32,
                  marginRight: 10,
                }}
              >
                Class Management
              </Typography>
              <div className={classes.btnAdd}>
                {checkPagePermission(currentUser?.roles, "ADDCLASS") && (
                  <Button
                    onClick={() => changeState("CREATE", null)}
                    startIcon={<AddCircleRoundedIcon />}
                  >
                    Create Class
                  </Button>
                )}
              </div>
            </Box>
            <Table
              changeState={changeState}
              classrooms={classrooms}
              loading={loading}
              sortValue={sortValue}
              setSortValue={setSortValue}
            />
            <div
              style={{ width: "100%", height: 50, backgroundColor: "white" }}
            >
              <Pagination
                count={pagination.totalPages}
                page={pagination.page}
                size="small"
                onChange={handlePagination}
                style={{
                  padding: 10,
                  position: "absolute",
                  right: 0,
                }}
              />
            </div>
            <AlertDialog
              open={open}
              onClose={handleRecheckOnExit}
              handleClick={handleRecheckOnExit}
              title={
                state === "CREATE" || state === "UPDATE" || state === "QUITEDITING"
                  ? state === "CREATE"
                    ? "Create Class"
                    : "Edit Class"
                  : state === "PATIENTDETAILS"
                  ? `Patient Details Record`
                  : state === "USERDETAILS"
                  ? ""
                  : `Class ID : ${selectData.classId}`
              }
              contentBtn={handleBtnDownload() as Array<string>}
              subTitle={handleSubTitle()}
              status={state === "PREVIEW" ? formik.values.status : ""}
            >
              <DialogContent>{handleDialogContent(state)}</DialogContent>
              {handleDialogAction()}
            </AlertDialog>
            <RecheckDialog
              open={openRecheck}
              handleClose={handleCloseRecheck}
              confirmNo={handleCloseRecheck}
              confirmYes={handleSubmit}
              confirmPopup={confirmPopup}
            />
          </div>
        </div>
      )}
    />
  );
};
export default ClassroomManagement;
