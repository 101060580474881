import axiosInstance from "../../index";

export interface ILogin {
  username: string;
  password: string;
}

export async function signInApi(data: ILogin) {
  return axiosInstance
    .post(`${process.env.REACT_APP_API}/v1/auth/login`, data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export async function signOutService(type: any) {
  let headers = {
    Authorization: `Bearer ${localStorage.getItem("tokenUser")}`,
    "Content-Type": "application/json",
  };
  return axiosInstance
    .post(`${process.env.REACT_APP_API}/api/users/signOut/`, type, {
      headers: headers,
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export async function verifyMyTokenApi(token: any) {  
  let headers = {
    //Authorization: `${sessionStorage.getItem("tokenUser")}`,
    Authorization: token
  };
  return axiosInstance
    .get(`${process.env.REACT_APP_API}/v1/users/me`, {
      headers: headers,
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
