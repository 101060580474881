
import axiosInstance from "..";
import { IScenario } from "../../components/pages/SceneManagement/ListOfScenario";
interface IScenarioId {
  id: string;
}
export async function createPatientsApi(data: any) {
  let headers = {
    Authorization: `${localStorage.getItem("tokenUser")}`,
  };
  return axiosInstance
    .post(
      `${process.env.REACT_APP_API}/v1/patient-characters/insert-many`,
      data,
      {
        headers: headers,
      }
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
export async function getPatientsApi(inputQuery: any) {
  let headers = {
    Authorization: `${localStorage.getItem("tokenUser")}`,
  };
  return axiosInstance
    .get(`${process.env.REACT_APP_API}/v1/patient-characters`, {
      headers: headers,
      params: inputQuery,
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
export async function updatePatientsApi(data: any) {
  let headers = {
    Authorization: `${localStorage.getItem("tokenUser")}`,
  };
  return axiosInstance
    .patch(
      `${process.env.REACT_APP_API}/v1/patient-characters/update-data`,
      data,
      {
        headers: headers,
      }
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export async function deletePatientsApi(data: any) {
  let headers = {
    Authorization: `${localStorage.getItem("tokenUser")}`,
  };
  return axiosInstance
    .patch(
      `${process.env.REACT_APP_API}/v1/patient-characters/deactivate`,
      data,
      {
        headers: headers,
      }
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export async function deletePatientsManyApi(data: any) {
  let headers = {
    Authorization: `${localStorage.getItem("tokenUser")}`,
  };
  return axiosInstance
    .patch(
      `${process.env.REACT_APP_API}/v1/patient-characters/deactivate-many`,
      data,
      {
        headers: headers,
      }
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

