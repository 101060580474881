
import axiosInstance from "../index";
export interface IRegister {
  img?: string;
  username?: string;
  email?: string;
  password?: string;
  fullName?: string;
  firstName?:string;
  lastName?:string;
  gender?: string;
  organization?: string;
  occupation?: string;
  birthDate?: string;
  tel?: string;
  userid?: string;
  oculusEmail?: string;
}
export async function vefifyEmail(id:string) {
  return axiosInstance
    .patch(`${process.env.REACT_APP_API}/v1/users/confirm-email/${id}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export async function handleFirstTimeService(id:string) {
  return axiosInstance
    .patch(`${process.env.REACT_APP_API}/v1/users/update-firstTime/${id}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export async function checkConfirmEmail(id:string) {
  return axiosInstance
    .get(`${process.env.REACT_APP_API}/v1/users/confirm-email-status/${id}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export async function fetchUsersApi() {
  let headers = {
    Authorization: `${localStorage.getItem("tokenUser")}`,
  };
  return axiosInstance
    .get(`${process.env.REACT_APP_API}/v1/users`, {
      headers: headers,
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export async function fetchUsersApiPagination(inputQuery: any) {
  let headers = {
    Authorization: `${localStorage.getItem("tokenUser")}`,
  };
  return axiosInstance
    .get(`${process.env.REACT_APP_API}/v1/users/pagination`, {
      headers: headers,
      params: inputQuery
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export async function addRegisterInstructorApi(data: IRegister) {
  let headers = {
    Authorization: `${localStorage.getItem("tokenUser")}`,
  };
  return axiosInstance
    .post(`${process.env.REACT_APP_API}/v1/users/register-instructor`, data, {
      headers: headers,
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
export async function addRegisterStudentApi(data: IRegister) {
  let headers = {
    Authorization: `${localStorage.getItem("tokenUser")}`,
  };
  return axiosInstance
    .post(`${process.env.REACT_APP_API}/v1/users/register-student`, data, {
      headers: headers,
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
export async function updateUserApi(data: IRegister) {
  let headers = {
    Authorization: `${localStorage.getItem("tokenUser")}`,
  };
  return axiosInstance
    .patch(`${process.env.REACT_APP_API}/v1/users/update-other-data`, data, {
      headers: headers,
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
export async function updateUserMe(data: IRegister) {
  let headers = {
    Authorization: `${localStorage.getItem("tokenUser")}`,
  };
  return axiosInstance
    .patch(`${process.env.REACT_APP_API}/v1/users/update-data`, data, {
      headers: headers,
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
export async function fetchOrganizationsApi() {
  let headers = {
    Authorization: `${localStorage.getItem("tokenUser")}`,
  };
  return axiosInstance
    .get(`${process.env.REACT_APP_API}/v1/organizations`, {
      headers: headers,
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
export async function approvedApi(data:any) {
  let headers = {
    Authorization: `${localStorage.getItem("tokenUser")}`,
  };
  return axiosInstance
    .patch(
      `${process.env.REACT_APP_API}/v1/users/approve`,
      data,
      {
        headers: headers,
      }
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
export async function disApproveApi(data:any) {
  let headers = {
    Authorization: `${localStorage.getItem("tokenUser")}`,
  };
  return axiosInstance
    .patch(
      `${process.env.REACT_APP_API}/v1/users/disapprove`,
      data,
      {
        headers: headers,
      }
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
export async function deleteUserApi(data:any) {
  let headers = {
    Authorization: `${localStorage.getItem("tokenUser")}`,
  };
  return axiosInstance
    .patch(
      `${process.env.REACT_APP_API}/v1/users/deactivate`,
      data,
      {
        headers: headers,
      }
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
export async function addRoleRegistrationApproveApi(data:any) {
  let headers = {
    Authorization: `${localStorage.getItem("tokenUser")}`,
  };
  return axiosInstance
    .patch(
      `${process.env.REACT_APP_API}/v1/users/add-role-registration-approver`,
      data,
      {
        headers: headers,
      }
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
export async function addRoleModifyPatientApi(data:any) {
  let headers = {
    Authorization: `${localStorage.getItem("tokenUser")}`,
  };
  return axiosInstance
    .patch(
      `${process.env.REACT_APP_API}/v1/users/add-role-modify-patients`,
      data,
      {
        headers: headers,
      }
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
export async function addRoleModifyScenarioApproveApi(data:any) {
  let headers = {
    Authorization: `${localStorage.getItem("tokenUser")}`,
  };
  return axiosInstance
    .patch(
      `${process.env.REACT_APP_API}/v1/users/add-role-modify-scenarios`,
      data,
      {
        headers: headers,
      }
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
export async function removeRoleRegistrationApproveApi(data:any) {
  let headers = {
    Authorization: `${localStorage.getItem("tokenUser")}`,
  };
  return axiosInstance
    .patch(
      `${process.env.REACT_APP_API}/v1/users/remove-role-registration-approver`,
      data,
      {
        headers: headers,
      }
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
export async function removeRoleModifyPatientsApi(data:any) {
  let headers = {
    Authorization: `${localStorage.getItem("tokenUser")}`,
  };
  return axiosInstance
    .patch(
      `${process.env.REACT_APP_API}/v1/users/remove-role-modify-patients`,
      data,
      {
        headers: headers,
      }
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
export async function removeRoleModifyScenariosApi(data:any) {
  let headers = {
    Authorization: `${localStorage.getItem("tokenUser")}`,
  };
  return axiosInstance
    .patch(
      `${process.env.REACT_APP_API}/v1/users/remove-role-modify-scenarios`,
      data,
      {
        headers: headers,
      }
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
export async function getImgApi(parames:string) {
  let headers = {
    Authorization: `${localStorage.getItem("tokenUser")}`,
  };
  return axiosInstance
    .get(
      `${process.env.REACT_APP_API}/v1/profile-images/${parames}`,
      {
        headers: headers,
      }
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
export async function getImgManyApi(parames:string) {
  let headers = {
    Authorization: `${localStorage.getItem("tokenUser")}`,
  };
  return axiosInstance
    .get(
      `${process.env.REACT_APP_API}/v1/profile-images/ids/${parames}`,
      {
        headers: headers,
      }
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
export async function resetPassword(data:any) {
  let headers = {
    Authorization: `${localStorage.getItem("tokenUser")}`,
  };
  return axiosInstance
    .patch(
      `${process.env.REACT_APP_API}/v1/users/update-password`,
      data,
      {
        headers: headers,
      }
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
export async function updateImageMeApi(data:FormData) {
  let headers = {
    Authorization: `${localStorage.getItem("tokenUser")}`,
    "Content-Type": "multipart/form-data"
  };
  return axiosInstance
    .patch(
      `${process.env.REACT_APP_API}/v1/users/update-image`,
      data,
      {
        headers: headers,
      }
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
export async function updateImageOtherApi(parames:string,data:FormData) {
  let headers = {
    Authorization: `${localStorage.getItem("tokenUser")}`,
    "Content-Type": "multipart/form-data"
  };
  return axiosInstance
    .patch(
      `${process.env.REACT_APP_API}/v1/users/update-other-image/${parames}`,
      data,
      {
        headers: headers,
      }
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
export async function readImgApi() {
  let headers = {
    Authorization: `${localStorage.getItem("tokenUser")}`,
  };
  return axiosInstance
    .get(
      `${process.env.REACT_APP_API}/v1/users/read-image`,
      {
        headers: headers,
      }
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}





